.itemStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    background-image: url("./../assets/communication_options.png");
    /*background-size: cover;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: max(100%, 100%);
    text-align: center;


}


.registrationPage2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    /*background-size: cover;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: max(50%, 100%);
    text-align: center;
    background-image: url("./../assets/demo_reports@2x.png");
}
.page3  {
    background-image: url("./../assets/vpc_communications_page.png");

}
.page1  {
    background-image: url("./../assets/vpc_ailogify.png");

}

.page-h1 {
    font-size: 1.5rem;
    color: #2f2f31;
    font-family: "Helvetica Neue", serif;
    font-weight: lighter;
    letter-spacing: 1px;
    text-align: left;
    margin: 25px 20px 15px 20%;
    padding-top: 20px;
}

.itemStyle h1 {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

}

.itemStyle.page2{
    background: none;
    /*background-size: cover;*/
    text-align: center;
}
