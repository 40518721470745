

.carousel-container {
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*background-color: #5d5e67;*/
    display: inline-block;
    width: 50%;
    /*position: relative;*/
    /*verticalAlign: middle;*/
    /*backgroundColor: #edeceb;*/
    whiteSpace: normal;
    height: 100vh;
    background: linear-gradient(to right, #ff7e5f, #feb47b);
}

.left-side {
    display: inline-block;
    width: 50%;
    height: 80vh;
    position: relative;
    background-color: #fdfdfd;
    /*whiteSpace: normal;*/
    text-align: left;


}

.left-side p {
    /*font-size: 1.5rem;*/
    font-weight: lighter;
    font-size: 18px;
    color: #404040;
    /*margin-top: 40px;*/
    padding-left: 9px;

    text-align: left;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
}

.left-side p a {
    color: #4c86af;
    text-decoration: none;
}

.left-side h2 {
    margin-bottom: 10px;
    min-width: 100% !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-size: 48px;
    text-align: left;
    color: #404040;
    letter-spacing: 2px;
    padding-bottom: 60px;
}

.left-side h3 {
    margin-bottom: 10px;
    min-width: 100% !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-size: 25px;
    text-align: left;
    color: #5d5e67;
    letter-spacing: 2px;
}

.left-side input {
    display: block;
    width: 95%;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 200;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.left-side select {
    display: block;
    width: 95%;
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 200;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.left-side button {
    display: block;
    /*margin-left: auto;*/
    padding: 10px 20px;
    background-color: #508bfa;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 200;
    letter-spacing: 2px;

}

.footer-demo {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 45%;
    /*height: 20px;*/
    min-width: 200px;
    /*background: #f8f8f8;*/
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #8d8d8d;
    font-size: smaller;
    /*margin-bottom: 10px;*/
    align-items: center;

}

.footer-demo a {
    color: #8d8d8d;
    text-decoration: none;
}

.logo-demo {
    /*padding: -10px;*/
    /*margin-right: 24px;*/
    height: 30px;
    width: 100px;
    /*padding-right: 200px;*/
    background-image: url("./../assets/voicepro_communications_logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    /*background-position: center;*/
}
.error {
    color: red !important;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@media (max-width: 768px) {
    .carousel-container {
        /*display: none;*/
        width: 100%;


    }
    .itemStyle h1 {
        font-size: 1.5rem;
    }
    .footer-demo {
        /*display: none;*/
        width: 95%;
    }

    .left-side {
        width: 100%;
        height: 100vh;
        padding: 20px;
    }
}
