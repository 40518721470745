/* login.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    width: 300px;
    text-align: center;
}

.input-field {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.error {
    color: red;
}

.login-links {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
}

.login-links a {
    color: #5d5e67;
    text-decoration: none;
    margin: 0 .50rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: lighter;
    letter-spacing: 1px;
}
